let Outpatient = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient
  let ServiceBaseAddress = serviceAddr
  /**
   * 获取药品列表
   * @param {*} categoryId
   * @param {*} pageIndex
   * @param {*} keyWord
   * @param {*} success
   * @param {*} error
   */
  this.DrugOrgList = function (keyWord, categoryId, state, startTime, endTime, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/DrugOrgList`
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    url += `/${categoryId}/${state}`
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 添加药品
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.AddDrugOrg = function (item, success, error) {
    if (item) {
      var url = ServiceBaseAddress + "/api/MZDrug/DrugOrg"
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        item,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 编辑药品
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.EditDrugOrg = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/DrugOrg"
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 获取药品详情
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.MZDrugOrgDetail = function (drugId, success, error) {
    if (drugId) {
      var url = ServiceBaseAddress + "/api/MZDrug/DrugOrg/" + drugId
      TokenClient.Get(
        url,
        true,
        null,
        null,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 删除药品
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.RemoveMZDrugOrg = function (id, success, error) {
    if (id) {
      var url = ServiceBaseAddress + `/api/MZDrug/DrugOrg/${id}`
      TokenClient.Delete(
        url,
        true,
        null,
        null,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 获取药品分类
   * @param {*} success
   * @param {*} error
   */
  this.GetOutpatientCategories = function (success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/Manager/Categories"
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--新增药品查询(前10条)
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.DrugByOrgIn = function (keyWord, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/DrugByOrgIn"
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--获取检查项列表
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.CheckItemList = function (keyWord, pageIndex, itemType, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/CheckItemList"
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}/${itemType}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 添加检查项
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.AddCheckItem = function (item, success, error) {
    if (item) {
      var url = ServiceBaseAddress + "/api/MZDrug/CheckItem"
      let map = {
        ...item
      }
      map.itemType = parseInt(map.itemType)
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        map,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 编辑检查项
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.EditCheckItem = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/CheckItem"
    let map = {
      ...item
    }
    map.itemType = parseInt(map.itemType)
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      map,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 删除检查项
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.RemoveCheckItem = function (id, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/CheckItem/${id}`
    TokenClient.Delete(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 获取检查项详情
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.CheckItemDetail = function (id, success, error) {
    if (drugId) {
      var url = ServiceBaseAddress + "/api/MZDrug/CheckItem/" + id
      TokenClient.Get(
        url,
        true,
        null,
        null,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 机构后台--获取服用要求列表
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.TakeRequestList = function (keyWord, pageIndex, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/TakeRequestList"
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 添加服用要求
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.AddTakeRequest = function (item, success, error) {
    if (item) {
      var url = ServiceBaseAddress + "/api/MZDrug/TakeRequest"
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        item,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 编辑服用要求
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.EditTakeRequest = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/TakeRequest"
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 删除服用要求
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.RemoveTakeRequest = function (id, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/TakeRequest/${id}`
    TokenClient.Delete(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 删除检查项
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.RemoveCheckItem = function (id, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/CheckItem/${id}`
    TokenClient.Delete(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 获取检查项详情
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.CheckItemDetail = function (id, success, error) {
    if (drugId) {
      var url = ServiceBaseAddress + "/api/MZDrug/CheckItem/" + id
      TokenClient.Get(
        url,
        true,
        null,
        null,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 机构后台--获取退库原因列表
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.RefundRepertoryList = function (keyWord, pageIndex, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/RefundRepertoryList"
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 添加退库原因
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.AddRefundRepertory = function (item, success, error) {
    if (item) {
      var url = ServiceBaseAddress + "/api/MZDrug/RefundRepertory"
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        item,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 编辑退库原因
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.EditRefundRepertory = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/RefundRepertory"
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 删除退库原因
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.RemoveRefundRepertory = function (id, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/RefundRepertory/${id}`
    TokenClient.Delete(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--获取医嘱列表
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.DoctorAdviceList = function (keyWord, pageIndex, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/DoctorAdviceList"
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 添加医嘱
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.AddDoctorAdvice = function (item, success, error) {
    if (item) {
      var url = ServiceBaseAddress + "/api/MZDrug/DoctorAdvice"
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        item,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 编辑医嘱
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.EditDoctorAdvice = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/DoctorAdvice"
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 删除医嘱
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.RemoveDoctorAdvice = function (id, success, error) {
    if (id) {
      var url = ServiceBaseAddress + `/api/MZDrug/DoctorAdvice/${id}`
      TokenClient.Delete(
        url,
        true,
        null,
        null,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 机构后台--门诊药品入库
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.AddDrugOrgIn = function (item, success, error) {
    if (item) {
      var url = ServiceBaseAddress + "/api/MZDrug/DrugOrgIn"
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        item,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 机构后台--门诊药品入库管理列表
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.DrugOrgInList = function (keyWord, batchNumber, categoryId, isEmpty, startTime, endTime, pageIndex, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/DrugOrgInList"
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (batchNumber) {
      url += `/${batchNumber}`
    } else {
      url += "/%20"
    }
    url = `${url}/${categoryId}/${isEmpty}`
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--门诊药品入库管理列表
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.DrugsOutList = function (keyWord, batchNumber, categoryId, isEmpty, startTime, endTime, pageIndex, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/DrugsOutList"
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (batchNumber) {
      url += `/${batchNumber}`
    } else {
      url += "/%20"
    }
    url = `${url}/${categoryId}/${isEmpty}`
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--门诊药品库存预警
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.DrugOrgInWarn = function (keyWord, categoryId, pageIndex, pageSize, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/DrugOrgInWarn"
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    url = `${url}/${categoryId}`
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    if (pageSize) {
      url += `/${pageSize}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--门诊药品有效期提醒
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.DrugOrgValidDate = function (categoryId, endTime, pageIndex, pageSize, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/DrugOrgValidDate"
    url = `${url}/${categoryId}`
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    if (pageSize) {
      url += `/${pageSize}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--门诊药品入库日志查询
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.DrugOrgInLog = function (keyWord, startTime, endTime, categoryId, batchNumber, supplier, pageIndex, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/DrugOrgInLog"
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    url = `${url}/${categoryId}`
    if (batchNumber) {
      url += `/${batchNumber}`
    } else {
      url += "/%20"
    }
    if (supplier) {
      url += `/${supplier}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  /**
   * 机构后台--获取添加药品信息(前10条)
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.FindInOrg = function (keyWord, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/FindInOrg/${keyWord}`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  /**
   * 机构后台--直接售药
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.DrugSell = function (item, success, error) {
    if (item) {
      var url = ServiceBaseAddress + "/api/MZDrug/DrugSell"
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        item,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 机构后台--待收费列表
   * @param {*} success
   * @param {*} error
   */
  this.DrugWaitPayMent = function (keyWord, startTime, endTime, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/DrugWaitPayMentOrg`
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台-- 待收费详情
   * @param {*} success
   * @param {*} error
   */
  this.DrugPayMentDetail = function (orderId, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/DrugPayMentDetail/${orderId}`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--收费
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.DrugPayMent = function (item, success, error) {
    if (item) {
      var url = ServiceBaseAddress + "/api/MZDrug/DrugPayMent"
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        item,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 机构后台--退费
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.DrugRefund = function (item, success, error) {
    if (item) {
      var url = ServiceBaseAddress + "/api/MZDrug/DrugRefund"
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        item,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }

  /**
   * 机构后台--已收费列表
   * @param {*} success
   * @param {*} error
   */
  this.DrugAlreadyPayment = function (keyWord, startTime, endTime, pageIndex, isNoShow, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/DrugAlreadyPaymentOrg`
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}/${isNoShow}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--直接售药订单列表
   * @param {*} success
   * @param {*} error
   */
  this.SellingDrugsOrg = function (keyWord, startTime, endTime, pageIndex,  success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/SellingDrugsOrg`
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  /**
   * 机构后台-- 已收费详情
   * @param {*} success
   * @param {*} error
   */
  this.PaymentedDetail = function (orderId, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/PaymentedDetail/${orderId}`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--已退费列表
   * @param {*} success
   * @param {*} error
   */
  this.DrugRefundListOrg = function (keyWord, startTime, endTime, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/DrugRefundListOrg`
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--退费申请列表
   * @param {*} success
   * @param {*} error
   */
  this.DrugRefundApplyList = function (keyWord, startTime, endTime, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/DrugRefundApplyList`
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台-- 已退费详情
   * @param {*} success
   * @param {*} error
   */
  this.DrugRefundDetail = function (orderId, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/DrugRefundDetail/${orderId}`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--欠款列表
   * @param {*} success
   * @param {*} error
   */
  this.DebtList = function (keyWord, startTime, endTime, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/DebtList`
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  /**
   * 机构后台-- 欠费详情
   * @param {*} success
   * @param {*} error
   */
  this.DebtDetail = function (orderId, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/DebtDetail/${orderId}`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--欠费列表还款
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.DebtRepayment = function (item, success, error) {
    if (item) {
      var url = ServiceBaseAddress + "/api/MZDrug/DebtRepayment"
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        item,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 机构后台--获取机构常用处方列表
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.PrecriptionListOrg = function (type, keyWord, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/PrecriptionListOrg/${type}`
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--添加门诊处方
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.AddMZPrecription = function (item, success, error) {
    if (item) {
      var url = ServiceBaseAddress + "/api/MZDrug/AddMZPrecription"
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        item,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 机构后台--编辑门诊处方
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.EditMZPrecription = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/EditMZPrecription"
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.PrecriptionDetail = function (id, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/PrecriptionDetail/${id}`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.RemovePrecription = function (id, success, error) {
    if (id) {
      var url = ServiceBaseAddress + `/api/MZDrug/prescripId/${id}`
      TokenClient.Delete(
        url,
        true,
        null,
        null,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }
  /**
   * 机构后台--个人常用处方列表
   * @param {*}
   * @param {*} success
   * @param {*} error
   */
  this.OrgCommonPrescription = function (type, keyWord, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/OrgCommonPrescription/${type}`
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 启用禁用药品
   * @param {any} success
   * @param {any} error
   */
  this.IsEnableDrug = function (id, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/IsEnableDrug/${id}`
    TokenClient.Put(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--载入
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.LoadPrescriptionOrg = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/LoadPrescriptionOrg"
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  /**
   * 机构后台--出药
   * @param {*} orderId
   * @param {*} success
   * @param {*} error
   */
  this.DrugCompleteOrg = function (orderId, imgPharmacist,success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/DrugCompleteOrg?orderId=${orderId}&imgPharmacist=${imgPharmacist}`
    TokenClient.Put(
      url,
      true,
      { "Content-Type": "application/json" },
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--完成取药
   * @param {*} orderId
   * @param {*} success
   * @param {*} error
   */
  this.MZConfirmReceipt = function (item, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/MZConfirmReceipt`
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  this.MZPrescriptionDetail = function (id, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/MZPrescriptionDetail/" + id
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  /**
   * 机构后台--退库
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.DrugOrgOut = function (item, success, error) {
    if (item) {
      var url = ServiceBaseAddress + "/api/MZDrug/DrugOrgOut"
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        item,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data)
            success(jsonObj)
          }
        },
        error
      )
    }
  }

  /**
   * 导入药品
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.ImportOrgDrugData = function (path, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/ImportOrgDrugData?filePath=${path}`
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 导入药品入库
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.ImportMZDrugOrgIn = function (path, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/ImportMZDrugOrgIn?filePath=${path}`
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 发货
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.MZDeliverGoods = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/MZDeliverGoods"
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  /**
   * 机构后台--门诊收入统计
   * @param {*} success
   * @param {*} error
   */
  this.MZInputInfo = function (orgId,keyWord, departmentId, startTime, endTime, pageIndex, pageSize, type, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/MZInputInfo/${orgId}`
    if (keyWord) {
      url += `/${keyWord}/${departmentId}`
    } else {
      url += `/%20/${departmentId}`
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    if (pageSize) {
      url += `/${pageSize}`
    }
    // type 开方医生类型 0：机构医生 1：平台专家医生
    if (type || type == 0) {
      url += `/${type}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--门诊退费统计
   * @param {*} success
   * @param {*} error
   */
  this.MZRefundInfo = function (orgId, keyWord, departmentId = 0, startTime, endTime, pageIndex, type, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/MZRefundInfo/${orgId}`
    if (keyWord) {
      url += `/${keyWord}/${departmentId}`
    } else {
      url += `/%20/${departmentId}`
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    if (type || type == 0) {
      url += `/${type}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  /**
  * 导入药品
  * @param {*} item
  * @param {*} success
  * @param {*} error
  */
  this.ImportOrgDrugData = function (path, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/ImportOrgDrugData?filePath=${path}`
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
  * 导入药品入库
  * @param {*} item
  * @param {*} success
  * @param {*} error
  */
  this.ImportMZDrugOrgIn = function (path, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/ImportMZDrugOrgIn?filePath=${path}`
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--药品销售统计
   * @param {*} keyWord 
   * @param {*} categoryId 
   * @param {*} pageIndex 
   * @param {*} success 
   * @param {*} error 
   */
  this.MZDrugSellInfo = function (orgId,keyWord, categoryId, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/MZDrugSellInfo/${orgId}`
    if (keyWord) {
      url += `/${keyWord}/${categoryId}`
    } else {
      url += `/%20/${categoryId}`
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.MZDrugSellOrderDetail = function (orgId, id, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/MZDrugSellOrderDetail/${orgId}/${id}`
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--医生业绩明细
   * @param {*} success
   * @param {*} error
   */
  this.MZDoctorPerformanceInfo = function (orgId,keyWord, departmentId, startTime, endTime, pageIndex, type, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/MZDoctorPerformanceInfo/${orgId}`
    if (keyWord) {
      url += `/${keyWord}/${departmentId}`
    } else {
      url += `/%20/${departmentId}`
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    if (type != null) {
      url += `/${type}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  this.MZPersonDetail = function (orgId,id, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/MZPersonDetail/${orgId}/${id}`
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  /**
   * 机构后台--其他费用统计
   * @param {*} success
   * @param {*} error
   */
  this.MZOtherChargesInfo = function (orgId,keyWord, orderType, startTime, endTime, pageIndex, type, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/MZOtherChargesInfo/${orgId}`
    if (keyWord) {
      url += `/${keyWord}/${orderType}`
    } else {
      url += `/%20/${orderType}`
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    if (type || type == 0) {
      url += `/${type}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--审方费用统计
   * @param {*} success
   * @param {*} error
   */
  this.MZAuditPerformanceInfo = function (orgId,keyWord, startTime, endTime, pageIndex, type, isPharmacist, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/MZAuditPerformanceInfo/${orgId}`
    if (keyWord) {
      url += `/${keyWord}`
    } else {
      url += `/%20`
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    url += `/${type}`
    url += `/${isPharmacist}`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
   * 机构后台--
   * @param {*} success
   * @param {*} error
   */
  this.MZAuditInputInfo = function (orgId, keyWord, departmentId, auditId, startTime, endTime, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/MZAuditInputInfo/${orgId}`
    if (keyWord) {
      url += `/${keyWord}/${departmentId}/${auditId}`
    } else {
      url += `/%20/${departmentId}/${auditId}`
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  /**
  * 机构后台--
  * @param {*} success
  * @param {*} error
  */
  this.MZAuditPharmacistInputInfo = function (keyWord, departmentId, auditId, startTime, endTime, pageIndex, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/MZAuditPharmacistInputInfo`
    if (keyWord) {
      url += `/${keyWord}/${departmentId}/${auditId}`
    } else {
      url += `/%20/${departmentId}/${auditId}`
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    if (pageIndex) {
      url += `/${pageIndex}`
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.MZDepartmentPerformanceInfo = function (item, success, error) {
    var url = ServiceBaseAddress + "/api/MZDrug/MZDepartmentPerformanceInfo"
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.getOrderDiagnoseDetail = function (id, success, error) {
    var url = ServiceBaseAddress + '/api/MZDrug/OrderDiagnose/' + id
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.GetAuditIndex = function (datetime, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/GetAuditIndex?datetime=${datetime}`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.GetMakeIndex = function (datetime, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/GetMakeIndex?datetime=${datetime}`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.GetIndex = function (success, error) {
    var url = ServiceBaseAddress + `/api/MzDrug/GetIndex`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  this.BusinessDepartmentMZInputInfo = function (item,orgId , startTime, endTime,  success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/BusinessDepartmentMZInputInfo/${orgId}`
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

  this.BusinessMZInputInfo = function (item, orgId, departmentId,startTime, endTime,  success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/BusinessMZInputInfo/${orgId}/${departmentId}`
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.BusinessMZInputList = function (item, orgId, keyWord, departmentId, startTime, endTime, pageIndex, pageSize, type, success, error) {
    var url = ServiceBaseAddress + `/api/MZDrug/BusinessMZInputList/${orgId}`
    if (keyWord) {
      url += `/${keyWord}/${departmentId}`
    } else {
      url += `/%20/${departmentId}`
    }
    if (startTime) {
      url += `/${startTime}`
    } else {
      url += "/%20"
    }
    if (endTime) {
      url += `/${endTime}`
    } else {
      url += "/%20"
    }
    url += `/${pageIndex}/${pageSize}/${type}`
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      item,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }

}

export { Outpatient }
